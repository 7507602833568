import {
  Box,
  ButtonGroup,
  Flex,
  Spacer,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
} from "@chakra-ui/react";
import React, { useState } from "react";
import LogTable from "../sales/LogTable";
import CakraInput from "../../components/CakraInput";
import Datepicker from "react-tailwindcss-datepicker";
import moment from "moment";
import { dateFormat } from "../../lib/utils";
import {
  ColumnsGenLogTransaksi,
  ColumnsGenReportCheckin,
  ColumnsGenReportECYSYS,
} from "../../lib/generalColumns";
import { useAuth } from "../../hooks/useAuth";
import ReportEcysysTable from "../../components/ReportEcysysTable";
import LoadingOverlay from "../../components/ui/LoadingOverlay";
import FilterDrawer from "./FilterDrawer";
import DownloadReport from "./DownloadReport";
import {
  COLOR_SCHEME_INFO_CARD_CHECK_IN,
  COLOR_SCHEME_INFO_CARD_TRANSACTION,
  TITLE_FORMAT_TRANSACTION,
} from "../../lib/constant";

const TAB_KEY = {
  checkIn: "checkin",
  transaction: "transaction",
  ecysys: "ecysys",
};

const customOrder = [
  "Total transaksi (IDR)",
  "Transaksi On Spot (IDR)",
  "Transaksi by Sales (IDR)",
  "Transaksi by Travel Agent (IDR)",
  "Cash (IDR)",
  "Via EDC",
  "Qris",
  "VA/Bank Transfer",
  "Transaksi Deposit (IDR)",
  "Ticket pax",
  "Transaksi Refund (IDR)",
];

const customOrderRow = {
  "Total transaksi (IDR)": 1,
  "Transaksi On Spot (IDR)": 2,
  "Transaksi by Sales (IDR)": 2,
  "Transaksi by Travel Agent (IDR)": 2,
  "Cash (IDR)": 3,
  "Via EDC": 3,
  Qris: 3,
  "VA/Bank Transfer": 3,
  "Transaksi Deposit (IDR)": 3,
  "Ticket pax": 1,
  "Transaksi Refund (IDR)": 1,
};

const AccountingReport = () => {
  const { user } = useAuth();
  const [date, setDate] = useState({
    startDate: moment(),
    endDate: moment(),
  });
  const [transactionDate, setTransactionDate] = useState({
    startDate: moment(),
    endDate: moment(),
  });
  const [ecysysDate, setEcysysDate] = useState({
    startDate: moment(),
    endDate: moment(),
  });
  const [genCheckinParams, setGenCheckinParams] = useState({
    start_date: dateFormat(date.startDate),
    end_date: dateFormat(date.endDate),
  });
  const [genTransactionParams, setGenTransactionParams] = useState({
    start_date: dateFormat(date.startDate),
    end_date: dateFormat(date.endDate),
  });
  const [genEcysysParams, setGenEcysysParams] = useState({
    date: dateFormat(date.startDate),
  });

  const [filterCheckIn, setFilterCheckIn] = useState(null);
  const [filterTransaction, setFilterTransaction] = useState(null);
  const [filterEcysys, setFilterEcysys] = useState(null);

  const handleDateChange = (dataDate) => {
    const generateParams = {
      ...genCheckinParams,
      start_date: dateFormat(dataDate.startDate),
      end_date: dateFormat(dataDate.endDate),
    };
    setDate(dataDate);
    setGenCheckinParams(generateParams);
  };

  const handleTransactionDateChange = (dataDate) => {
    const generateParams = {
      ...genTransactionParams,
      start_date: dateFormat(dataDate.startDate),
      end_date: dateFormat(dataDate.endDate),
    };
    setTransactionDate(dataDate);
    setGenTransactionParams(generateParams);
  };

  const handleEcysysDateChange = (dataDate) => {
    const generateParams = {
      ...genEcysysParams,
      date: dateFormat(dataDate.startDate),
    };
    setEcysysDate(dataDate);
    setGenEcysysParams(generateParams);
  };

  const handleSubmitFilter = (data) => {
    const { selectedHelpdesk, selectedSales, selectedTa, checkBoxOption } =
      data;

    const generateParams = {
      ...genCheckinParams,
      sales_id: selectedSales?.value || null,
      helpdesk_id: selectedHelpdesk?.value || null,
      travel_agent_id: selectedTa?.value || null,
      role: checkBoxOption || [],
    };
    setGenCheckinParams(generateParams);
    setFilterCheckIn(data);
  };

  const handleTransactionSubmitFilter = (data) => {
    const { selectedHelpdesk, selectedSales, selectedTa, checkBoxOption } =
      data;
    const generateParams = {
      ...genTransactionParams,
      sales_id: selectedSales?.value || null,
      helpdesk_id: selectedHelpdesk?.value || null,
      travel_agent_id: selectedTa?.value || null,
      role: checkBoxOption || [],
    };
    setGenTransactionParams(generateParams);
    setFilterTransaction(data);
  };

  const handleEcysysSubmitFilter = (data) => {
    const { selectedHelpdesk, selectedSales, selectedTa, checkBoxOption } =
      data;
    const generateParams = {
      ...genEcysysParams,
      sales_id: selectedSales?.value || null,
      helpdesk_id: selectedHelpdesk?.value || null,
      travel_agent_id: selectedTa?.value || null,
      role: checkBoxOption || [],
    };
    setGenEcysysParams(generateParams);
    setFilterEcysys(data);
  };

  return (
    <div className="min-h-screen">
      <div className="border-b-2 flex justify-between px-5">
        <div className="py-5 items-center mr-2">
          <h1>Laporan</h1>
        </div>
      </div>

      <Tabs isLazy className="py-4">
        <div className="flex justify-between border-b-2">
          <TabList width={"100%"} borderColor={"transparent"}>
            <Tab>Check in</Tab>
            <Tab>Transaksi</Tab>
            <Tab>ECSYS</Tab>
          </TabList>
        </div>

        <TabPanels>
          <TabPanel p={0} py={2}>
            <Flex minWidth="max-content" alignItems="center" gap="2">
              <Box
                display={"flex"}
                flexDirection={"row"}
                maxW={"sm"}
                mx={4}
                alignItems={"center"}
                width={"-webkit-fill-available"}
                gap={1}
              >
                <Text className="text-gray-700 text-lg">Hightlight</Text>
                <CakraInput>
                  <Datepicker
                    useRange={false}
                    asSingle={false}
                    displayFormat="DD MMM YYYY"
                    popoverDirection=""
                    inputClassName={
                      "bg-inherit  text-black w-full border-inherit rounded-lg z-[999]"
                    }
                    placeholder={"Pilih tanggal"}
                    value={date}
                    onChange={handleDateChange}
                    containerClassName="relative bg-white"
                    readOnly={true}
                  />
                </CakraInput>
              </Box>
              <Spacer />
              <ButtonGroup>
                <FilterDrawer
                  genParams={filterCheckIn}
                  handleSubmitFilter={handleSubmitFilter}
                />
                <DownloadReport genParams={genCheckinParams} />
              </ButtonGroup>
            </Flex>

            <LogTable
              summaryCard={true}
              api="/api/report-log-check-in"
              columns={ColumnsGenReportCheckin}
              params={genCheckinParams}
              tab={TAB_KEY.checkIn}
              summaryFormat={{ bgColor: COLOR_SCHEME_INFO_CARD_CHECK_IN }}
            />
          </TabPanel>
          <TabPanel p={0} py={2}>
            <Flex minWidth="max-content" alignItems="center" gap="2">
              <Box
                display={"flex"}
                flexDirection={"row"}
                maxW={"sm"}
                mx={4}
                alignItems={"center"}
                width={"-webkit-fill-available"}
                gap={1}
              >
                <Text className="text-gray-700 text-lg">Hightlight</Text>
                <CakraInput>
                  <Datepicker
                    useRange={false}
                    asSingle={false}
                    displayFormat="DD MMM YYYY"
                    popoverDirection=""
                    inputClassName={
                      "bg-inherit  text-black w-full border-inherit rounded-lg z-[999]"
                    }
                    placeholder={"Pilih tanggal"}
                    value={transactionDate}
                    onChange={handleTransactionDateChange}
                    containerClassName="relative bg-white"
                    readOnly={true}
                  />
                </CakraInput>
              </Box>
              <Spacer />
              <ButtonGroup>
                <FilterDrawer
                  genParams={filterTransaction}
                  handleSubmitFilter={handleTransactionSubmitFilter}
                />
                <DownloadReport genParams={genTransactionParams} />
              </ButtonGroup>
            </Flex>
            <LogTable
              api="/api/report-log-transactions"
              columns={ColumnsGenLogTransaksi}
              params={genTransactionParams}
              tab={TAB_KEY.transaction}
              summaryCard={true}
              summaryFormat={{
                bgColor: COLOR_SCHEME_INFO_CARD_TRANSACTION,
                title: TITLE_FORMAT_TRANSACTION,
              }}
              queueFormat={customOrder}
              rowConfig={customOrderRow}
              infoCustomRow={true}
            />
          </TabPanel>
          <TabPanel p={0} py={2}>
            <Flex minWidth="max-content" alignItems="center" gap="2">
              <Box
                display={"flex"}
                flexDirection={"row"}
                maxW={"sm"}
                mx={4}
                alignItems={"center"}
                width={"-webkit-fill-available"}
                gap={1}
              >
                <CakraInput>
                  <Datepicker
                    useRange={false}
                    asSingle={true}
                    displayFormat="DD MMM YYYY"
                    popoverDirection=""
                    inputClassName={
                      "bg-inherit  text-black w-full border-inherit rounded-lg"
                    }
                    placeholder={"Pilih tanggal"}
                    value={ecysysDate}
                    onChange={handleEcysysDateChange}
                    containerClassName="relative bg-white"
                    readOnly={true}
                  />
                </CakraInput>
              </Box>
              <Spacer />
              <ButtonGroup>
                <FilterDrawer
                  genParams={filterEcysys}
                  handleSubmitFilter={handleEcysysSubmitFilter}
                />
                <DownloadReport
                  genParams={{
                    ...genEcysysParams,
                    start_date: genEcysysParams.date,
                    end_date: genEcysysParams.date,
                  }}
                />
              </ButtonGroup>
            </Flex>
            <ReportEcysysTable
              api="/api/report-log-ecsys"
              columns={ColumnsGenReportECYSYS}
              params={genEcysysParams}
              tab={TAB_KEY.ecysys}
            />
          </TabPanel>
        </TabPanels>
      </Tabs>
      <LoadingOverlay />
    </div>
  );
};

export default AccountingReport;
