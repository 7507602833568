import { Card, CardBody, CardFooter, Divider } from "@chakra-ui/react";
import React, { Fragment } from "react";
import { cn } from "../../lib/utils";

const CardStack = ({
  bodyData,
  footerData = [],
  bodyClassName = "",
  footerClassName = "",
  title,
  textBodyClassName = "",
}) => {
  return (
    <Card minWidth="33%">
      <CardBody
        className={cn("text-center rounded-b-lg z-10 px-2", bodyClassName)}
      >
        <h1 className={cn("font-bold text-4xl", textBodyClassName)}>
          {bodyData}
        </h1>
        <p className="text-sm capitalize">{title}</p>
      </CardBody>
      {footerData.length > 0 && (
        <CardFooter
          className={cn(
            "-mt-5 z-0 text-gray-50 text-xs font-bold !pb-2 !pt-6 rounded-b-lg capitalize justify-center",
            footerClassName
          )}
        >
          {footerData.map((item, idx) => (
            <Fragment key={idx}>
              {`${item?.sku_name} ${item?.total}` || "-"}
              {idx < footerData.length - 1 && (
                <Divider
                  className="!bg-gray-50 mx-1"
                  orientation="vertical"
                  borderLeftWidth="2px"
                />
              )}
            </Fragment>
          ))}
        </CardFooter>
      )}
    </Card>
  );
};

export default CardStack;
