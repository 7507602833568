import {
  getCoreRowModel,
  getPaginationRowModel,
  useReactTable,
} from "@tanstack/react-table";
import React, { useEffect } from "react";
import { useFetchDataDashboard } from "../../hooks/useFetchDataDashboard";
import DataTable from "../../components/ui/dataTable/DataTable";
import CommonAlert from "../../components/CommonAlert";
import InfoHeader from "../accounting/InfoHeader";
import InfoHeaderCustomRow from "../accounting/InfoHeaderCustomRow";

const LogTable = ({
  api,
  columns,
  params,
  summaryCard = false,
  summaryFormat,
  queueFormat = [],
  infoCustomRow = false,
  rowConfig = {},
}) => {
  const [columnFilters, setColumnFilters] = React.useState([]);
  const [rowSelection, setRowSelection] = React.useState({});
  const [query, setQuery] = React.useState("");
  const [pagination, setPagination] = React.useState({
    pageIndex: 0,
    pageSize: 5,
  });
  const [dataUser, setDataUser] = React.useState([]);
  const [infoSummary, setInfoSummary] = React.useState([]);

  const { loading, data, error, setParam, refetch } = useFetchDataDashboard(
    api,
    {
      page: pagination.pageIndex + 1,
      limit: pagination.pageSize,
      query: query,
      ...params,
    }
  );

  useEffect(() => {
    if (!loading && data && !error) {
      if (data.data) {
        setDataUser(data.data || []);
      } else {
        setDataUser(data);
      }
      setInfoSummary(data?.summaries || []);
    } else if (!loading && data && error) {
      setDataUser([]);
    }
  }, [data, loading, error]);

  useEffect(() => {
    setParam({
      page: pagination.pageIndex + 1,
      limit: pagination.pageSize,
      query: query,
      ...params,
    });
  }, [pagination, query, params]);

  const table = useReactTable({
    data: dataUser,
    columns,
    rowCount: data?.total_data || 0, // Set the total rows based on
    // pageCount: Math.ceil(data?.total / pagination.pageSize) || 0, // Set the total pages based on your data
    manualPagination: true,
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    onColumnFiltersChange: setColumnFilters,
    onRowSelectionChange: setRowSelection,
    getRowId: (row) => row.id,
    onPaginationChange: setPagination,
    state: {
      columnFilters,
      rowSelection,
      pagination,
    },
  });

  return (
    <div>
      {summaryCard ? (
        !infoCustomRow ? (
          <InfoHeader
            queueFormat={queueFormat}
            infoFormat={summaryFormat}
            infoData={infoSummary || []}
          />
        ) : (
          <InfoHeaderCustomRow
            queueFormat={queueFormat}
            infoFormat={summaryFormat}
            infoData={infoSummary || []}
            rowConfig={rowConfig}
          />
        )
      ) : null}
      <CommonAlert error={error} data={data} loading={loading} />
      <DataTable columns={columns} table={table} loading={loading} />
    </div>
  );
};

export default LogTable;
